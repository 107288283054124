import { Injectable } from '@angular/core';
import { Router } from  "@angular/router";
import { AngularFireAuth } from  "@angular/fire/auth";
import { User } from  '../../models/user.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // displayDashboardHome
  user: User;
  message: string;
  title: string;

  constructor(
    private toastr: ToastrService,
    private firebaseAuth: AngularFireAuth,
    private firebase: AngularFireDatabase,
    private router: Router,
    private translate: TranslateService) { }

  signInWithEmail(email: string, password: string) {
    console.log(email, password)
    this.firebaseAuth.auth.signInWithEmailAndPassword(email, password)
      .then(
        res => {

          // if (res.user.emailVerified !== true) {
          //   this.sendVerificationMail();

          //   this.message = this.translate.instant("REGISTER.NOT_VERIFIED");
          //   this.title = this.translate.instant("TITLE.ERROR");

          //   this.toastr.error(this.message, this.title, {
          //     timeOut: 4000
          //   });
          // }
          // else {

            localStorage.setItem('uid', res.user.uid);
            localStorage.setItem('email', res.user.email);
            sessionStorage.setItem('isLoggedIn', 'true');
            this.router.navigate(["dashboard"]);
          // }
        })
      .catch(
        err => {
          if (err.message.includes('The user account has been disabled')) {
            this.message = this.translate.instant("LOGIN.LOGIN_FAILED");
            this.title = this.translate.instant("TITLE.ERROR");

            this.toastr.error(this.message, this.title, {
              timeOut: 4000
            });
          }
          else if (err.message.includes('There is no user record')) {
            this.message = this.translate.instant("LOGIN.LOGIN_NO_USER");
            this.title = this.translate.instant("TITLE.ERROR");

            this.toastr.error(this.message, this.title, {
              timeOut: 4000
            });
          }
          else {
            this.message = this.translate.instant("LOGIN.LOGIN_NO_USER");
            this.title = this.translate.instant("TITLE.ERROR");

            this.toastr.error(this.message, this.title, {
              timeOut: 4000
            });
          }
        });
  }

  signUpWithEmail(email: string, password: string) {
    const objectToPush = {
      role: 'user',
      email: email,
      uid: ''
    };

    this.firebaseAuth.auth.createUserWithEmailAndPassword(email, password)
      .then(res => {
        objectToPush.uid = res.user.uid;
        this.firebase.list('users').push(objectToPush);

        // this.firebaseAuth.auth.currentUser.sendEmailVerification();

        this.message = this.translate.instant("REGISTER.SUCCESS");
        this.title = this.translate.instant("TITLE.SUCCESS");

        this.toastr.success(this.message, this.title, {
          timeOut: 4000
        });

        this.router.navigate([""]);
      })
      .catch(err => {
        if (err.message.includes('A network error')) {
          this.message = this.translate.instant("REGISTER.FAIL");
          this.title = this.translate.instant("TITLE.ERROR");

          this.toastr.error(this.message, this.title, {
            timeOut: 4000
          });
        }
        else {
          this.message = this.translate.instant("REGISTER.ERROR");
          this.title = this.translate.instant("TITLE.ERROR");

          this.toastr.error(this.message, this.title, {
            timeOut: 4000
          });
        }
      })
  }

  passwordReset(email) {
    this.firebaseAuth.auth.sendPasswordResetEmail(email)
    .then(res => {
      this.message = this.translate.instant("PASSWORD_RESET.SUCCESS");
      this.title = this.translate.instant("PASSWORD_RESET.TITLE");

      this.toastr.success(this.message, this.title, {
        timeOut: 4000
      });
    })
    .catch(err => {
      if (err.message.includes('A network error')) {
        this.message = this.translate.instant("REGISTER.FAIL");
        this.title = this.translate.instant("TITLE.ERROR");
      }
      else if (err.message.includes('There is no user record')) {
        this.message = this.translate.instant("LOGIN.LOGIN_NO_USER");
        this.title = this.translate.instant("TITLE.ERROR");
      }

      this.toastr.error(this.message, this.title, {
        timeOut: 4000
      });
    })
  }

  sendVerificationMail() {
    return this.firebaseAuth.auth.currentUser.sendEmailVerification()
    .then(() => {
      this.router.navigate(['login']);
    })
  }
}
