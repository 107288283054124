import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login/login.service';
import { Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { PassResetComponent } from '../dialogs/pass-reset/pass-reset.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  defaultLang: string;
  hide     = true;
  
  loginForm = this.formBuilder.group({
    email           : ['', [Validators.required, Validators.email]],
    password        : ['', [Validators.required, Validators.minLength(6)]]
  });

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private translate: TranslateService,
    private dialog: MatDialog) {}

  ngOnInit() {
    console.log("lang: " + localStorage.getItem('defaultLang'))
    if (localStorage.getItem('defaultLang') === null) {
      this.defaultLang = this.translate.getBrowserLang();

      if (!this.defaultLang.match("en|el")) {
        localStorage.setItem('defaultLang', 'en');
        this.defaultLang = "en";
      }
    }
    else
      this.defaultLang = localStorage.getItem('defaultLang');

    this.translate.use(this.defaultLang);
  }

  onSubmit() {
    this.loginService.login(this.loginForm.get('email').value, 
                            this.loginForm.get('password').value);
  }

  onClear() {
    this.loginForm.reset();
  }

  setLang(lang: string) {
    localStorage.setItem('defaultLang', lang);
    this.translate.use(lang);
  }

  resetPassword() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.open(PassResetComponent, {width: '400px',});
  }
}
