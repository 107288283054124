import { Component, OnInit } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import * as CryptoJS from 'crypto-js';
import { IMqttMessage, MqttService, MqttConnectionState } from 'ngx-mqtt';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user/user.service';
import { StoreService } from 'src/app/services/store/store.service';
import { MatDialogConfig, MatDialogRef, MatDialog } from '@angular/material';
import { NoLocationComponent } from '../dialogs/no-location/no-location.component';
import { MatChipInputEvent } from '@angular/material';

export interface Fruit {
  name: string;
  sub: any;
}

@Component({
  selector: 'app-smartcontrol',
  templateUrl: './smartcontrol.component.html',
  styleUrls: ['./smartcontrol.component.css']
})
export class SmartcontrolComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  isChecked = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  fruits: Fruit[] = [];

  public message: string;
  public topicSub: string;
  public topicMess: string;
  public decryptedMessage: string;
  public encryptedMessage: string;

  lat: number;
  lng: number;
  arr: any[];
  user = [];
  store = [];
  name: string;
  connected: boolean;
  publishForm: FormGroup;
  subscribeForm: FormGroup;
  private subscription: Subscription;
  // Form state
  loading = false;
  success = false;

  constructor(
    private _mqttService: MqttService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private storeService: StoreService,
    private dialog: MatDialog
  ) {
      // check connection
      this._mqttService.state.subscribe((value)=>{
        switch(value) {
          case MqttConnectionState.CLOSED:
            this.connected = false;
            console.log("connection lost");
            break;
          case MqttConnectionState.CONNECTED:
          this.connected = true;
          console.log("connected");
            break;
        }
      });



  }

  ngOnInit() {


    //console.log('subscribe');
    //this.topicSub = this.subscribeForm.value.topictopicSub;
    //this.topicSub = 'Test';
  //   this.subscription = this._mqttService
  //                       .observe('Test')
  //                       .subscribe((message: IMqttMessage) => {
  //   this.message = message.payload.toString();
  //   console.log('this.message',this.message);

  //   // decrypt the message
  //   this.decryptedMessage = CryptoJS.AES
  //                             .decrypt(this.message, environment.encryptionKey.key)
  //                             .toString(CryptoJS.enc.Utf8);
  // });
    this.publishForm = this.formBuilder.group({
      topic      : ['', [Validators.required]],
      message    : ['', [Validators.required]]
    });

    this.subscribeForm = this.formBuilder.group({
      topicSub     : ['', [Validators.required]]
    });

    const defaultLang = localStorage.getItem('defaultLang');
    this.translate.use(defaultLang);
  }


  sendMQTT(){
    this._mqttService.unsafePublish(environment.MQTTCONFIG.topic_update_state,'>>>>  Ok  <<<<');
  }

  onClearPublishForm() {
    this.publishForm.reset();
  }

  onClearSubsribeForm() {
    this.subscribeForm.reset();
  }


  async submitPublish(){

    if (this.isChecked) {
      this.encryptedMessage = CryptoJS.AES.encrypt(this.publishForm.value.message, "12345678910123456789012345678901").toString();

      this._mqttService.unsafePublish(this.publishForm.value.topic,this.encryptedMessage);

    } else {
      this._mqttService.unsafePublish(this.publishForm.value.topic,this.publishForm.value.message);
    }
  }


  submitSubscribe(){
    console.log('subscribe');
    this.topicSub = this.subscribeForm.value.topicSub;
    this.subscription = this._mqttService
                        .observe(this.topicSub)
                        .subscribe((message: IMqttMessage) => {
                          this.topicMess = message.topic.toString();
                          this.message = message.payload.toString();

                          console.log('this.topicMess>>>>>>',this.topicMess);
                          console.log('this.message',this.message);

    // decrypt the message
  //   this.decryptedMessage = CryptoJS.AES
  //                             .decrypt(this.message, environment.encryptionKey.key)
  //                             .toString(CryptoJS.enc.Utf8);
   });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {

      this.subscription = this._mqttService
      .observe(value.trim())
      .subscribe((message: IMqttMessage) => {
        this.topicMess = message.topic;
        this.message = message.payload.toString();
        console.log('this.topicMess>>>>>>',this.topicMess);
        console.log('this.message',this.message);


});


      this.fruits.push({name: value.trim(),
                        sub: this.subscription});

    console.log('value.trim()',value.trim());


  }
 // Reset the input value
 if (input) {
  input.value = '';
}

  }

  remove(fruit: Fruit): void {
    const index = this.fruits.indexOf(fruit);
    console.log('fruit.name',this.fruits[index].name);
    this.fruits[index].sub.unsubscribe();

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }
  onToggleChange() {
    console.log(!this.isChecked);
  }
}

