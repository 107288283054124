import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { StoreService } from 'src/app/services/store/store.service';
import { UserService } from 'src/app/services/user/user.service';
import { Location } from '@angular-material-extensions/google-maps-autocomplete';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  uid: string;
  store_key: string;
  user = [];
  store = [];
  coords: any;

  detailsForm = this.formBuilder.group({
    name       : ['', [Validators.required]],
    phone_num  : ['', [Validators.required]],
    country    : ['', [Validators.required]],
    city       : ['', [Validators.required]],
    timezone   : ['', [Validators.required]],
    address    : ['', [Validators.required]],
    zip_code   : ['', [Validators.required]],
    lat        : [''],
    lng        : ['']
  });

  constructor(
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private storeService: StoreService,
    private userService: UserService) { }

  ngOnInit() {
    this.uid = localStorage.getItem('uid');

    this.userService.getUser(this.uid)
      .subscribe(res => {
        this.user = res;

        this.store_key = this.user[0].store_key;

        if (this.user[0].store_key) {
          this.storeService.getStore(this.store_key).subscribe(res => {
            this.store = res;

            this.detailsForm.controls['name'].setValue(this.store[0].name);
            this.detailsForm.controls['phone_num'].setValue(this.store[0].phone_num);
            this.detailsForm.controls['country'].setValue(this.store[0].country);
            this.detailsForm.controls['timezone'].setValue(this.store[0].timezone);
            this.detailsForm.controls['address'].setValue(this.store[0].address);
            this.detailsForm.controls['city'].setValue(this.store[0].city);
            this.detailsForm.controls['zip_code'].setValue(this.store[0].zip_code);
            this.detailsForm.controls['lat'].setValue(this.store[0].lat)
            this.detailsForm.controls['lng'].setValue(this.store[0].lng)
          })
        }

      })
  }

  onClear() {
    this.detailsForm.reset();
  }

  onSave() {
    // console.log("defaultLang: " + localStorage.defaultLang)
    console.log("coords: " + this.coords)
    // console.log("Address to save: " + this.detailsForm.controls['address'].value);
    if (this.coords) {
      this.detailsForm.controls['lat'].setValue(this.coords.lat);
      this.detailsForm.controls['lng'].setValue(this.coords.lng);
    }

    console.log("this.detailsForm.value: " + this.detailsForm.value)

    this.storeService.saveStore(this.detailsForm.value, this.uid);
  }

  onAutocompleteSelected(result: PlaceResult) {
    // console.log('onAutocompleteSelected: ', result);
    // console.log('address url: ', result.url);
    // console.log('onAutocompleteSelected: ', result.address_components[0].long_name);
    // console.log('onAutocompleteSelected: ', result.address_components[1].long_name);
    // console.log('onAutocompleteSelected: ', result.address_components[2].long_name);
    // console.log('onAutocompleteSelected: ', result.address_components[3].long_name);
    // console.log('onAutocompleteSelected: ', result.address_components[4].long_name);
    // console.log('onAutocompleteSelected: ', result.address_components[5].long_name);

    let address = result.address_components[1].long_name + " " + result.address_components[0].long_name;
    this.detailsForm.controls['address'].setValue(address);
    this.detailsForm.controls['city'].setValue(result.address_components[3].long_name);
    this.detailsForm.controls['zip_code'].setValue(result.address_components[5].long_name);
  }

  onLocationSelected(location: Location) {
    this.coords = { lat: location.latitude, lng: location.longitude }
    console.log('onLocationSelected: ', location);
  }
}
