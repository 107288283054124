import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database'; 
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/user.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  message: string;
  title: string;
  users : Observable<any>;
  user: User[] = [];

  constructor(
    private firebase: AngularFireDatabase,
    private toastr: ToastrService,
    private translate: TranslateService,
    private authService: AuthService) { }

  getUser(uid: string): Observable<User[]> {
    return this.firebase.list('users', ref => ref.orderByChild('uid').equalTo(uid)).snapshotChanges()
    .pipe(
      map(res => {
        return res.map(element => {
          let user = element.payload.toJSON();
          user["$key"] = element.key;
          return user as User;
        });
    }));
  }

  getUserByKey(key: string): Observable<User[]> {
    return this.firebase.list('users', ref => ref.orderByKey().equalTo(key)).snapshotChanges()
    .pipe(
      map(res => {
        return res.map(element => {
          let user = element.payload.toJSON();
          user["$key"] = element.key;
          return user as User;
        });
    }));
  }

  getUsers(): Observable<User[]> {
    return this.firebase.list('users').snapshotChanges()
      .pipe(
        map(res => {
          return res.map(element => {
            let user = element.payload.toJSON();
            user["$key"] = element.key;
            return user as User;
          });
      }));
  }

  updateUser(user) {
    this.firebase.object('users/' + user.$key).update({
      firstname: user.firstname,
      lastname: user.lastname,
      store_key: user.store_key
    });
  }

  deleteUser(key: string) {    
    this.getUserByKey(key).subscribe(res => {
      this.user = res;

      let store_key = this.user[0].store_key;
      let user_key  = this.user[0].$key;
      let user_id   = this.user[0].uid;

      console.log(store_key, user_key);

      if (store_key) {
        this.firebase.list('stores/', ref => ref.orderByKey().equalTo(store_key))
          .remove(store_key);
      }
      
      this.firebase.list('users/', ref => ref.orderByKey().equalTo(user_key))
        .remove(user_key);
    })

    this.message = this.translate.instant("TITLE.SUCCESS"); 
    this.title = this.translate.instant("USERS.DELETE");
    this.toastr.success(this.message, this.title, {
      timeOut: 4000
    });
  }
}
