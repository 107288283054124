import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { PassResetComponent } from '../dialogs/pass-reset/pass-reset.component';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  uid: string;
  user = [];
  key: string;

  profileForm = this.formBuilder.group({
    firstname       : [''],
    lastname        : [''],
    email           : [{value: '', disabled: "true"}]
  });

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private userService: UserService) { }

  ngOnInit() {

    this.uid = localStorage.getItem('uid');

    this.userService.getUser(this.uid)
      .subscribe(res => {
        this.user = res;

        this.profileForm.controls['firstname'].setValue(this.user[0].firstname);
        this.profileForm.controls['lastname'].setValue(this.user[0].lastname);
        this.profileForm.controls['email'].setValue(this.user[0].email);
        // this.key = this.user[0].$key;
      })
  }

  onSubmit() {
    this.user[0].firstname = this.profileForm.get('firstname').value;
    this.user[0].lastname = this.profileForm.get('firstname').value;

    this.userService.updateUser(this.user[0]);
  }

  resetPassword() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.open(PassResetComponent, {width: '400px',});
  }
}
