import { Component, OnInit, Input } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginService } from 'src/app/services/login/login.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit{

  visible: boolean;
  email: string;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private loginService: LoginService,
    private translate: TranslateService) {}

    ngOnInit() {
      this.visible = true;
      this.email = localStorage.getItem('email');

      const defaultLang = localStorage.getItem('defaultLang');
      this.translate.use(defaultLang);
    }

    onLogOut() {
      this.loginService.logout();
    }

    show(event: boolean) {
      console.log("Dashboard show() event: " + event);
      this.visible = event;
      console.log("Dashboard show() this.visible: " + this.visible);
    }

    hide(event: boolean) {
      console.log("Dashboard hide() event: " + event);
      this.visible = event;
      console.log("Dashboard hide() this.visible: " + this.visible);
    }

    setLang(lang: string) {
      localStorage.setItem('defaultLang', lang);
      console.log("defaultLang: " + localStorage.defaultLang)

      this.translate.use(lang);
    }
}
