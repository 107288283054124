import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router/';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate{

  constructor(private router: Router) { }

  canActivate() {
    if (sessionStorage.getItem('isLoggedIn')) {
        return true;
    }

    this.router.navigate(['dashboard']);
    return false;
  }
}
