import { Component, OnInit } from '@angular/core';
import { Geolocation } from '../../models/geolocation.model';
import * as CryptoJS from 'crypto-js';
import { IMqttMessage, MqttService, MqttConnectionState } from 'ngx-mqtt';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GeolocationService } from 'src/app/services/geolocation/geolocation.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user/user.service';
import { StoreService } from 'src/app/services/store/store.service';
import { MatDialogConfig, MatDialogRef, MatDialog } from '@angular/material';
import { NoLocationComponent } from '../dialogs/no-location/no-location.component';

@Component({
  selector: 'app-geolocation',
  templateUrl: './geolocation.component.html',
  styleUrls: ['./geolocation.component.css']
})
export class GeolocationComponent implements OnInit {
  
  geolocationsList: Array<Geolocation> = [];
  private subscription: Subscription;
  public message: string;
  geoLocation: Geolocation;
  markers: Array<Geolocation> = [];
  lat: number;
  lng: number;
  arr: any[];
  user = [];
  store = [];
  name: string;

  constructor(
    private _mqttService: MqttService,
    private geolocationService: GeolocationService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private userService: UserService,
    private storeService: StoreService,
    private dialog: MatDialog) {

      // check connection
      this._mqttService.state.subscribe((value)=>{
        switch(value) {
          case MqttConnectionState.CLOSED:
            console.log("connection lost");
            break;
          case MqttConnectionState.CONNECTED:
          console.log("connected");
            break;
        }
      });

      console.log('subscribe');
      this.subscription = this._mqttService
                          .observe(environment.MQTTCONFIG.topic_update_state)
                          .subscribe((message: IMqttMessage) => {
      this.message = message.payload.toString();

      // decrypt the message
      let decryptedMessage = CryptoJS.AES
                                .decrypt(this.message, environment.encryptionKey.key)
                                .toString(CryptoJS.enc.Utf8);
      
      this.geoLocation = JSON.parse(decryptedMessage);

      let item = this.markers.find(marker => marker.userId == this.geoLocation.userId);

      if (item) {
        let index = this.markers.indexOf(item);
        this.markers[index] = this.geoLocation;
      }
      else {
        if (this.markers.length === 0) {
          this.geoLocation.userId = this.name;
          this.geoLocation.lat = this.lat;
          this.geoLocation.lng = this.lng;

          this.markers.push(this.geoLocation);
        }
        else
          this.markers.push(this.geoLocation);
      }

    });
  }

  ngOnInit() {
    console.log("onInit this.markers: " + this.markers);

    const uid = localStorage.getItem('uid');

    this.userService.getUser(uid)
      .subscribe(res => {
        this.user = res;
        const store_key = this.user[0].store_key;
        
        if (store_key) {
          this.storeService.getStore(store_key).subscribe(res => {
            this.store = res;

            this.name = this.store[0].name;

            this.geolocationService.getCoordinates(this.store[0].address, this.store[0].city, this.store[0].zip_code)
            .then((response) => {
              this.arr = response.results;
              console.log(this.arr);
              this.lat = this.arr[0].geometry.location.lat;
              this.lng = this.arr[0].geometry.location.lng;

              console.log("this.lat: " + this.lat);
              console.log("this.lng: " + this.lng);
              },
              (error) => {
                alert("Error: " + error.statusText)
              })
          });
        }
        else {
          console.log("Location is not defined");
          const dialogConfig = new MatDialogConfig();

          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;  
      
          let dialogRef:MatDialogRef<NoLocationComponent> = this.dialog.open(NoLocationComponent, {width: '300px',});
        }
      })

    const defaultLang = localStorage.getItem('defaultLang');
    this.translate.use(defaultLang);
  }

  public ngOnDestroy() {
    this.markers = [];
    this.subscription.unsubscribe();
  }

  isInfoWindowOpen(userId) {
    return userId;
  }

  onClearMap() {
    this.markers = [];
    this.geoLocation.userId = this.name;
    this.geoLocation.lat = this.lat;
    this.geoLocation.lng = this.lng;

    this.markers.push(this.geoLocation);

    let message = this.translate.instant("GEOLOCATION.CLEAR_MAP"); 
    let title = this.translate.instant("TITLE.SUCCESS");
    this.toastr.success(message, title, {
      timeOut: 4000
    });
  }
}
