import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import {
  MatButtonModule, MatCardModule, MatDialogModule, MatInputModule, MatTableModule,
  MatToolbarModule, MatMenuModule,MatIconModule, MatProgressSpinnerModule, MatSidenavModule,
  MatListModule, MatExpansionModule, MatSelectModule, MatButtonToggleModule, MatPaginatorModule,
  MatSortModule
} from '@angular/material';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import { PassResetComponent } from '../components/dialogs/pass-reset/pass-reset.component';
import { DeleteUserComponent } from '../components/dialogs/delete-user/delete-user.component';
import { NoLocationComponent } from '../components/dialogs/no-location/no-location.component';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    MatExpansionModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatSortModule,
    MatSlideToggleModule
  ],
  exports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    MatExpansionModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatPaginatorModule,
    MatSortModule,
    MatSlideToggleModule
   ],
   entryComponents: [
    PassResetComponent,
    DeleteUserComponent,
    NoLocationComponent
  ]
})

export class MaterialModule { }
