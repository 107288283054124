import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AuthguardService } from './services/authguard/authguard.service';
import { UsersComponent } from './components/users/users.component';
import { ProfileComponent } from './components/profile/profile.component';
import { GeolocationComponent } from './components/geolocation/geolocation.component';
import { RegisterComponent } from './components/register/register.component';
import { DetailsComponent } from './components/restaurant/details/details.component';
import { CuisineComponent } from './components/restaurant/cuisine/cuisine.component';
import { SmartcontrolComponent } from './components/smartcontrol/smartcontrol.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthguardService],
    children: [
      { path: 'smartcontrol',   component: SmartcontrolComponent },
      { path: 'home',    redirectTo: '', pathMatch: 'full' },
      { path: 'users',   component: UsersComponent },
      { path: 'geolocation',   component: GeolocationComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'details', component: DetailsComponent },
      { path: 'cuisine', component: CuisineComponent }
    ]
  },
  { path: 'register', component: RegisterComponent },
  { path: 'login',    component: LoginComponent },

  { path: '',         component: LoginComponent },
  { path: '**',       component: PageNotFoundComponent }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [
    RouterModule
  ],
})
export class AppRoutingModule { }
