import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { MatPaginator, MatTableDataSource, MatDialogConfig, MatDialog, MatDialogRef, MatSort } from '@angular/material';
import { User } from 'src/app/models/user.model';
import { DeleteUserComponent } from '../dialogs/delete-user/delete-user.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  userList: User[];

  displayedColumns: string[] = ['name', 'lastname', 'role', 'email', 'delete'];
  dataSource: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private userService: UserService,
    private dialog: MatDialog) { }

  ngOnInit() {    

    this.userService.getUsers().subscribe(users => {
      this.userList = users;
      const ELEMENT_DATA: User[] = users;
      this.dataSource = new MatTableDataSource<User>(ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  onDelete(key: string, email: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;  

    let dialogRef:MatDialogRef<DeleteUserComponent> = this.dialog.open(DeleteUserComponent, {width: '400px',});
    dialogRef.componentInstance.email = email;
    dialogRef.componentInstance.key = key;
  }
}