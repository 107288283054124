import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-no-Location',
  templateUrl: './no-Location.component.html',
  styleUrls: ['./no-Location.component.css']
})
export class NoLocationComponent implements OnInit {

  email: string
  key: string;

  constructor(
    private dialogRef: MatDialogRef<NoLocationComponent>,
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    this.dialogRef.close();
  }
}
