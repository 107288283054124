import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { MustMatch } from './must-match/must-match.validator';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { auth } from 'firebase';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  defaultLang: string;
  hide  = true;
  hide2 = true;

  registerForm = this.formBuilder.group({
    email           : ['', [Validators.required, Validators.email]],
    password        : ['', [Validators.required, Validators.minLength(6)]],
    confirmPassword : ['', Validators.required]    
  },
  {
    validator: MustMatch('password', 'confirmPassword')
  })

  constructor(
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private auth: AuthService) { }

  ngOnInit() {
    this.defaultLang = localStorage.getItem('defaultLang');
    this.translate.use(this.defaultLang);
  }

  onSubmit() {
    this.auth.signUpWithEmail(
        this.registerForm.get('email').value,
        this.registerForm.get('password').value)
  }

  onClear() {
    this.registerForm.reset();
  }

}
