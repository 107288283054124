import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './core/material.module';
import { LayoutModule } from '@angular/cdk/layout';
import { AngularFireModule,  } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { environment } from '../environments/environment';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AgmCoreModule } from '@agm/core';
import { ToastrModule } from 'ngx-toastr';
import { IMqttMessage, MqttModule, IMqttServiceOptions } from 'ngx-mqtt';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { UsersComponent } from './components/users/users.component';
import { HomeComponent } from './components/home/home.component';
import { ProfileComponent } from './components/profile/profile.component';
import { GeolocationComponent } from './components/geolocation/geolocation.component';
import { RegisterComponent } from './components/register/register.component';
import { PassResetComponent } from './components/dialogs/pass-reset/pass-reset.component';
import { DetailsComponent } from './components/restaurant/details/details.component';
import { CuisineComponent } from './components/restaurant/cuisine/cuisine.component';
import { DeleteUserComponent } from './components/dialogs/delete-user/delete-user.component';
import { NoLocationComponent } from './components/dialogs/no-location/no-location.component';
import { SmartcontrolComponent } from './components/smartcontrol/smartcontrol.component';
import { MatSidenavModule } from '@angular/material';
import { MatChipsModule } from '@angular/material';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    PageNotFoundComponent,
    NavbarComponent,
    UsersComponent,
    HomeComponent,
    ProfileComponent,
    GeolocationComponent,
    RegisterComponent,
    PassResetComponent,
    DetailsComponent,
    CuisineComponent,
    DeleteUserComponent,
    NoLocationComponent,
    SmartcontrolComponent
  ],
  exports: [MatSidenavModule],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatChipsModule,
    AppRoutingModule,
    MatSidenavModule,
    MaterialModule,
    LayoutModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBon4PXD6sA5y8_cIalZG9trOa95ypjCOM',
      language: localStorage.defaultLang,
      libraries: ['places', 'drawing', 'geometry']
    }),
    MqttModule.forRoot(environment.MQTTCONFIG),
    ToastrModule.forRoot(),
    TranslateModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
    }),
    HttpClientModule,
    MatGoogleMapsAutocompleteModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
