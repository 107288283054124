// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCoAc3rZwm7kNapLQT-qu8ZdPhieEJUI80",
    authDomain: "smartrestaurant-fd7cf.firebaseapp.com",
    databaseURL: "https://smartrestaurant-fd7cf.firebaseio.com",
    projectId: "smartrestaurant-fd7cf",
    storageBucket: "smartrestaurant-fd7cf.appspot.com",
    messagingSenderId: "821869154382"
  },
  google_maps: {
    apiKey: "AIzaSyBon4PXD6sA5y8_cIalZG9trOa95ypjCOM"
  },
  MQTTCONFIG: {
    broker: 'smartanalysis.ddns.net',
    hostname: 'smartanalysis.ddns.net',
    // broker: 'iot.eclipse.org',
    // hostname: 'iot.eclipse.org',
    protocol: 'wss',
    port: 9001,
    username: 'smartanalysis',
    password: '1234',
    path: '/',
    topic_query: 'smartorder/query',
    topic_update_state: 'smartorder/geolocation',
    clientID: 'smartorder',
    // rejectUnauthorized: false
  },
  encryptionKey: {
    key: '12345678910123456789012345678901'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
