import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog) { }

  login(email: string, password: string) {

    this.authService.signInWithEmail(email, password);
  }

  logout(){
    sessionStorage.clear();
    
    this.router.navigate(["/login"]);
  }
}
