import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Store } from 'src/app/models/store.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GeolocationService } from '../geolocation/geolocation.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  store_key: string;
  user_key: string;
  message: string;
  title: string;
  user: any;

  constructor(
    private firebase: AngularFireDatabase,
    private toastr: ToastrService,
    private translate: TranslateService,
    private geolocationService: GeolocationService) { }

  saveStore(value, uid) {
    this.firebase.list('users', ref => ref.orderByChild('uid').equalTo(uid)).snapshotChanges()
    .subscribe(res => {
      this.user = res[0].payload.toJSON();
      this.store_key = this.user.store_key;
      this.user_key  = res[0].key;
      
      console.log("value.lat: " + value.lat)

      this.geolocationService.getAddress(value.lat, value.lng)
        .then(res => {
          const status = res.status;

          if (status === "OK") {
            // if the store_key exists in the users table, update the existing record in table stores
            // else create a new and add the store's key in the users table
            if (this.store_key) 
              this.updateStore(this.store_key, value);
            else {
              this.store_key = this.firebase.list('stores').push(value).key;   

              this.firebase.object('users/' + this.user_key).update({
                store_key: this.store_key
              });

              this.message = this.translate.instant("STORE.SUCCESS"); 
              this.title = this.translate.instant("TITLE.SUCCESS");
              this.toastr.success(this.message, this.title, {
                timeOut: 4000
              });
            }
          }
          else {
            this.message = this.translate.instant("GEOLOCATION.ADDRESS_ERROR"); 
            this.title = this.translate.instant("TITLE.ERROR");
            this.toastr.error(this.message, this.title, {
              timeOut: 4000
            });  
          }          
        })     
    })
  }

  getStore(key): Observable<Store[]> {
    return this.firebase.list('stores', ref => ref.orderByKey().equalTo(key)).snapshotChanges()
      .pipe(
        map(res => {
          return res.map(element => {
            let store = element.payload.toJSON();
            store["$key"] = element.key;
            return store as Store;
          });
      }));
    
  }

  updateStore(key: string, value: string) {
    this.firebase.object('stores/' + key).update(value);

    this.message = this.translate.instant("STORE.UPDATE"); 
    this.title = this.translate.instant("TITLE.SUCCESS");
    this.toastr.success(this.message, this.title, {
      timeOut: 4000
    });
  }
}
