import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GoogleMaps } from '../../models/googlemaps.model';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  url: string = 'https://maps.googleapis.com/maps/api/geocode/json?';
  api: string = 'AIzaSyBon4PXD6sA5y8_cIalZG9trOa95ypjCOM';

  constructor(private http: HttpClient) { }

  getCurrentLocation(): Promise<any> {
    if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(position => {
          resolve({
            lng: position.coords.longitude,
            lat: position.coords.latitude         
          });
        },
        err => {
          reject(err);
        });
      });
    }
    else
      console.log("Geolocation is not supported by this browser.");
  }

  getCoordinates(address: string, city: string, zip_code: string) {
    let addressToSearch = '';
    // console.log("address: " + address)
    let addressSplit = address.split(" ");
    // console.log("addressSplit: " + addressSplit)
    for (let i = 0; i < addressSplit.length; i++) {
      addressToSearch = addressToSearch + addressSplit[i] + '+';
    }

    addressToSearch =  addressToSearch + city + "+" + zip_code;
    console.log("addressToSearch: " + addressToSearch)
    let promise = new Promise<GoogleMaps>((resolve, reject) => {
      this.http.get(this.url + 'address=' + addressToSearch + '&key=' + this.api)
        .toPromise()
        .then((response) => {
          resolve(response as GoogleMaps)
          console.log("url: " + this.url + 'address=' + addressToSearch + '&key=' + this.api);
        }, (error) => {
          reject(error);
        })
    })
    
    return promise;
  }

  getAddress(lat: string, lng: string) {
    if (navigator.geolocation) {
      let promise = new Promise<GoogleMaps>((resolve, reject) => {
        this.http.get(this.url + 'latlng=' + lat + ',' + lng + '&key=' + this.api)
          .toPromise()
          .then((response) => {
            resolve(response as GoogleMaps)
            console.log("url: " + this.url + 'latlng=' + lat + ',' + lng + '&key=' + this.api);
          }, (error) => {
            reject(error);
          })
      })
      
      return promise;
    }
  }
}
