import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  uid: string;
  user = [];
  isAdmin: boolean = true;
  
  
  // showHideBoolean: boolean = true;
  @Output() show: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @Output() hide: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  constructor(
    private translate: TranslateService,
    private userService: UserService) { }

  ngOnInit() {

    this.uid = localStorage.getItem('uid');

    this.userService.getUser(this.uid)
      .subscribe(res => {

        if (res.length > 0) {
          this.user = res;
          
          if (this.user[0].role === "user")
            this.isAdmin = false;
          // else if (this.user[0].role === "admin")
          //   this.isAdmin = true;
        }

      }); 

    const defaultLang = localStorage.getItem('defaultLang');
    this.translate.use(defaultLang);
  }

  showElement(){
    this.show.emit(true);
  }

  hideElement(){
    this.hide.emit(false);
  }
}
