import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.css']
})
export class DeleteUserComponent implements OnInit {

  email: string
  key: string;

  constructor(
    private dialogRef: MatDialogRef<DeleteUserComponent>,
    private userService: UserService
  ) { }

  ngOnInit() {
  }

  onSubmit() {

    this.userService.deleteUser(this.key)

    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }

}
