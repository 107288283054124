import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireAuth } from  "@angular/fire/auth";
import { MatDialogRef } from '@angular/material';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-pass-reset',
  templateUrl: './pass-reset.component.html',
  styleUrls: ['./pass-reset.component.css']
})
export class PassResetComponent implements OnInit {

  resetForm = this.formBuilder.group({
    email           : ['', [Validators.required, Validators.email]]
  });

  message: string;
  title: string;

  constructor(
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    public firebaseAuth: AngularFireAuth,
    private dialogRef: MatDialogRef<PassResetComponent>) { }

  ngOnInit() {
    const defaultLang = localStorage.getItem('defaultLang');
    this.translate.use(defaultLang);
  }


  onSubmit() {
    this.authService.passwordReset(this.resetForm.get('email').value);
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }
}
